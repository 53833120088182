import { useEffect, useState } from "react";

/**
 * Detect if a user has added a website to the home screen on iOS using the "Save to Home Screen" feature.
 * @returns
 */
const useDetectStandalone = () => {
  const [isStandalone, setIsStandalone] = useState(false);

  useEffect(() => {
    // Check if in standalone mode (PWA added to home screen)
    if (window?.navigator?.standalone) {
      setIsStandalone(true);
    } else {
      setIsStandalone(false);
    }
  }, []);

  return isStandalone;
};

export default useDetectStandalone;
